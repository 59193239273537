import { Component, Vue } from 'vue-property-decorator';
import DeliveryDto from '@/app/types/DeliveryDto';

@Component
class DeliveryDetailService extends Vue {
  public get delivery(): DeliveryDto {
    return this.$store.state['appMainModule'].delivery;
  }
}

export default DeliveryDetailService;
