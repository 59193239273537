
import { Component, Mixins } from 'vue-property-decorator';
import DeliveryService from '@/app/services/delivery.service';
import DeliveryDetail from '@/app/delivery/DeliveryDetail.vue';
import Payments from '@/app/components/Payments.vue';

@Component({
  components: {
    DeliveryDetail,
    Payments,
  }
})
export default class Delivery extends Mixins(DeliveryService) {}
