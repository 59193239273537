import DeliveryDto from '@/app/types/DeliveryDto';
import InvoiceDto from '@/app/types/InvoiceDto';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true, name: 'appMain' })
export default class AppMainModule extends VuexModule {
  token = '';

  invoiceId = '';

  invoice: InvoiceDto | null = null;

  isPaymentPopupShown = false;

  isHowToPayBrivaPopupShown = false;

  isHowToPayBnivaPopupShown = false;

  isHowToPayMandirivaPopupShown = false;

  isHowToPayIndomaretPopupShown = false;

  isHowToPayAlfamartPopupShown = false;

  deliveryId = '';

  delivery: DeliveryDto | null = null;

  @Mutation
  setToken(token: string): void {
    this.token = token;
  }

  @Mutation
  setInvoiceId(id: string): void {
    this.invoiceId = id;
  }

  @Mutation
  setInvoice(invoice: InvoiceDto | null): void {
    this.invoice = invoice;
  }

  @Mutation
  setPaymentPopup(value: boolean): void {
    this.isPaymentPopupShown = value;
  }

  @Mutation
  setHowToPayBriva(value: boolean): void {
    this.isHowToPayBrivaPopupShown = value;
  }

  @Mutation
  setHowToPayBniva(value: boolean): void {
    this.isHowToPayBnivaPopupShown = value;
  }

  @Mutation
  setHowToPayMandiriva(value: boolean): void {
    this.isHowToPayMandirivaPopupShown = value;
  }

  @Mutation
  setHowToPayIndomaret(value: boolean): void {
    this.isHowToPayIndomaretPopupShown = value;
  }

  @Mutation
  setHowToPayAlfamart(value: boolean): void {
    this.isHowToPayAlfamartPopupShown = value;
  }

  @Mutation
  setDeliveryId(value: string): void {
    this.deliveryId = value;
  }

  @Mutation
  setDelivery(value: DeliveryDto | null): void {
    this.delivery = value;
  }
}
