import { Component, Vue } from 'vue-property-decorator';
import InvoiceDto from '@/app/types/InvoiceDto';

@Component
class InvoiceDetailService extends Vue {
  public get invoice(): InvoiceDto {
    return this.$store.state['appMainModule'].invoice;
  }
}

export default InvoiceDetailService;
