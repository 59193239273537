import HowToPay from '@/app/types/HowToPay';

const HowToPayMandiriva: HowToPay[] = [
  {
    title: 'ATM Mandiri',
    list: [
      'Masukkan Kartu ATM dan PIN',
      'Pilih menu "Bayar/Beli"',
      'Pilih menu "Lainnya", hingga menemukan menu "Multipayment"',
      'Masukkan Kode Biller Winpay (88898), lalu pilih tombol Benar',
      'Masukkan "Nomor Virtual Account"',
      'Masukkan Nominal yang akan dibayarkan <b>(Harus sama dengan nominal di Aplikasi/Website),</b> lalu pilih tombol Benar',
      'Masukkan Angka "1" untuk memilih tagihan, lalu pilih tombol Ya',
      'Akan muncul konfirmasi pembayaran, lalu pilih tombol Ya',
      'Simpan struk sebagai bukti pembayaran Anda',
    ],
  },
  {
    title: 'Mandiri internet banking / mandiri online',
    list: [
      'Login Mandiri Online dengan memasukkan username dan password',
      'Pilih menu "Pembayaran"',
      'Pilih menu "Multipayment"',
      'Pilih penyedia jasa "Winpay"',
      'Masukkan nomor Virtual Account dan masukkan nominal yang akan dibayarkan, <b>(Harus sama dengan nominal di Aplikasi/Website),</b> lalu pilih Lanjut ',
      'Setelah muncul tagihan, pilih Konfirmasi',
      'Masukkan PIN / Challenge Code Token',
      'Transaksi selesai, simpan bukti bayar Anda',
    ],
  },
];

export default HowToPayMandiriva;
