import HowToPay from '@/app/types/HowToPay';

const HowToPayBniva: HowToPay[] = [
  {
    title: 'ATM BNI',
    list: [
      'Masukkan Kartu Debit BRI dan PIN Anda',
      'Masukkan Kartu Anda.',
      'Pilih Bahasa.',
      'Masukkan PIN ATM Anda.',
      'Pilih "Menu Lainnya".',
      'Pilih "Transfer".',
      'Pilih "Rekening Tabungan".',
      'Pilih "Ke Rekening BNI".',
      'Masukkan nomor rekening tujuan dengan 16 digit Nomor Virtual Account.',
      'Masukkan nominal transfer sesuai tagihan atau kewajiban Anda. Nominal yang berbeda tidak dapat diproses.',
      'Konfirmasi, apabila telah sesuai, lanjutkan transaksi.',
      'Transaksi telah selesai.',
    ],
  },
  {
    title: 'IBANK personal',
    list: [
      'Ketik alamat https://ibank.bni.co.id kemudian klik "Enter".',
      'Masukkan User ID dan Password.',
      'Klik menu "TRANSFER" kemudian pilih "TAMBAH REKENING FAVORIT". Jika menggunakan desktop untuk menambah rekening, pada menu "Transaksi" lalu pilih "Info &amp; Administrasi Transfer" kemudian "Atur Rekening Tujuan" lalu "Tambah Rekening Tujuan".',
      'Masukkan nomor Virtual Account sebagai nomor rekening tujuan',
      'Masukkan Kode Otentikasi Token. Nomor rekening tujuan berhasil ditambahkan.',
      'Kembali ke menu "TRANSFER". Pilih "TRANSFER ANTAR REKENING BNI", kemudian pilih rekening tujuan.',
      'Pilih Rekening Debit dan ketik nominal transfer sesuai tagihan atau kewajiban Anda. Nominal yang berbeda tidak dapat diproses.',
      'Lalu masukkan kode otentikasi token.',
      'Transfer Anda Telah Berhasil.',
    ],
  },
  {
    title: 'Mobile Banking',
    list: [
      'Akses BNI Mobile Banking dari handphone kemudian masukkan user ID dan password.',
      'Pilih menu Transfer.',
      'Pilih "Antar Rekening BNI" kemudian "Input Rekening Baru".',
      'Masukkan nomor Rekening Debit dan nomor Virtual Account Tujuan',
      'Masukkan nominal transfer sesuai tagihan atau kewajiban Anda. Nominal yang berbeda tidak dapat diproses.',
      'Konfirmasi transaksi dan masukkan Password Transaksi.',
      'Transfer Anda Telah Berhasil.',
    ],
  },
  {
    title: 'SMS Banking',
    list: [
      'Buka aplikasi SMS Banking BNI',
      'Pilih menu Transfer',
      'Pilih menu Trf rekening BNI',
      'Masukkan nomor rekening tujuan dengan 16 digit Nomor Virtual Account',
      'Masukkan nominal transfer sesuai tagihan atau kewajiban Anda. Nominal yang berbeda tidak dapat diproses.',
      'Pilih "Proses" kemudian "Setuju"',
      'Reply sms dengan ketik pin sesuai perintah',
      'Transaksi Berhasil',
    ],
  },
  {
    title: 'ATM bersama',
    list: [
      'Masukkan kartu ke mesin ATM Bersama.',
      'Pilih "Transaksi Lainnya".',
      'Pilih menu "Transfer".',
      'Pilih "Transfer ke Bank Lain".',
      'Masukkan kode bank BNI (009) dan 16 Digit Nomor Virtual Account',
      'Masukkan nominal transfer sesuai tagihan atau kewajiban Anda. Nominal yang berbeda tidak dapat diproses.',
      'Konfirmasi rincian Anda akan tampil di layar, cek dan tekan "Ya" untuk melanjutkan.',
      'Transaksi Berhasil.',
    ],
  },
  {
    title: 'Transfer dari bank lain',
    list: [
      'Pilih menu "Transfer antar bank" atau "Transfer online antarbank".',
      'Masukkan kode bank BNI (009) atau pilih bank yang dituju yaitu BNI.',
      'Masukan 16 Digit Nomor Virtual Account pada kolom rekening tujuan,',
      'Masukkan nominal transfer sesuai tagihan atau kewajiban Anda. Nominal yang berbeda tidak dapat diproses.',
      'Masukkan jumlah pembayaran.',
      'Konfirmasi rincian Anda akan tampil di layar, cek dan apabila sudah sesuai silahkan lanjutkan transaksi sampai dengan selesai.',
      'Transaksi Berhasil.',
    ],
  },
];

export default HowToPayBniva;
