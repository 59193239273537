
import { Component, Mixins } from 'vue-property-decorator';
import PopupContainer from '@/app/components/PopupContainer.vue';
import PaymentPopupService from '@/app/services/payment-popup.service';

@Component({
  components: {
    PopupContainer,
  }
})
export default class PaymentPopup extends Mixins(PaymentPopupService) {}
