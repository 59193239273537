
import { Component, Mixins } from 'vue-property-decorator';
import InvoiceService from '@/app/services/invoice.service';
import InvocieDetail from '@/app/invoice/InvoiceDetail.vue';
import Payments from '@/app/components/Payments.vue';

@Component({
  components: {
    InvocieDetail,
    Payments,
  }
})
export default class Invoice extends Mixins(InvoiceService) {}
