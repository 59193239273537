import { Component, Vue } from 'vue-property-decorator';

@Component
class CommonPaymentService extends Vue {
  public setHowToPay(channelCode: string): void {
    if (channelCode === 'BRIVA') {
      this.$store.commit('appMainModule/setHowToPayBriva', true);
    } else if (channelCode === 'BNIVA') {
      this.$store.commit('appMainModule/setHowToPayBniva', true);
    } else if (channelCode === 'MANDIRIVA') {
      this.$store.commit('appMainModule/setHowToPayMandiriva', true);
    } else if (channelCode === 'INDOMARET') {
      this.$store.commit('appMainModule/setHowToPayIndomaret', true);
    } else if (channelCode === 'ALFAMART') {
      this.$store.commit('appMainModule/setHowToPayAlfamart', true);
    }
  }

  public setCopyPaymentCode(paymentCode: string): void {
    const blobType = 'text/plain';
    const clipboardItemSupported = typeof ClipboardItem !== 'undefined';

    if (clipboardItemSupported === false) {
      navigator.clipboard.writeText(paymentCode ?? '');
      return;
    }

    navigator.clipboard.write([
      new ClipboardItem({
        [blobType]: new Blob([paymentCode ?? ''], { type: blobType }),
      }),
    ]);
  }

  private async toDataURL(url: string): Promise<string> {
    return fetch(url).then((response) => {
      return response.blob();
    }).then(blob => {
      return URL.createObjectURL(blob);
    });
  }

  public async setDownloadQRCode(paymentCode: string): Promise<void> {
    const a = document.createElement('a');
    a.href = await this.toDataURL(paymentCode);
    a.download = '';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}

export default CommonPaymentService;
