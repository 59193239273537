import { Component, Vue } from 'vue-property-decorator';
import PaymentPopup from '@/app/invoice/PaymentPopup.vue';
import HowToPayBrivaPopup from '@/app/invoice/HowToPayBrivaPopup.vue';
import HowToPayBnivaPopup from '@/app/invoice/HowToPayBnivaPopup.vue';
import HowToPayMandirivaPopup from '@/app/invoice/HowToPayMandirivaPopup.vue';
import HowToPayIndomaretPopup from '@/app/invoice/HowToPayIndomaretPopup.vue';
import HowToPayAlfamartPopup from '@/app/invoice/HowToPayAlfamartPopup.vue';

@Component({
  components: {
    PaymentPopup,
    HowToPayBrivaPopup,
    HowToPayBnivaPopup,
    HowToPayMandirivaPopup,
    HowToPayIndomaretPopup,
    HowToPayAlfamartPopup,
  }
})
class PaymentsService extends Vue {
  public get isPaymentPopupShown(): boolean {
    return this.$store.state['appMainModule'].isPaymentPopupShown;
  }

  public set isPaymentPopupShown(val: boolean) {
    this.$store.commit('appMainModule/setPaymentPopup', val);
  }

  public get isHowToPayBrivaPopupShown(): boolean {
    return this.$store.state['appMainModule'].isHowToPayBrivaPopupShown;
  }

  public set isHowToPayBrivaPopupShown(val: boolean) {
    this.$store.commit('appMainModule/setHowToPayBriva', val);
  }

  public get isHowToPayBnivaPopupShown(): boolean {
    return this.$store.state['appMainModule'].isHowToPayBnivaPopupShown;
  }

  public set isHowToPayBnivaPopupShown(val: boolean) {
    this.$store.commit('appMainModule/setHowToPayBniva', val);
  }

  public get isHowToPayMandirivaPopupShown(): boolean {
    return this.$store.state['appMainModule'].isHowToPayMandirivaPopupShown;
  }

  public set isHowToPayMandirivaPopupShown(val: boolean) {
    this.$store.commit('appMainModule/setHowToPayMandiriva', val);
  }

  public get isHowToPayIndomaretPopupShown(): boolean {
    return this.$store.state['appMainModule'].isHowToPayIndomaretPopupShown;
  }

  public set isHowToPayIndomaretPopupShown(val: boolean) {
    this.$store.commit('appMainModule/setHowToPayIndomaret', val);
  }

  public get isHowToPayAlfamartPopupShown(): boolean {
    return this.$store.state['appMainModule'].isHowToPayAlfamartPopupShown;
  }

  public set isHowToPayAlfamartPopupShown(val: boolean) {
    this.$store.commit('appMainModule/setHowToPayAlfamart', val);
  }
}

export default PaymentsService;
