import { Component, Mixins } from 'vue-property-decorator';
import DeliveryDto from '@/app/types/DeliveryDto';
import CommonPaymentService from './common-payment.service';

@Component
class DeliveryService extends Mixins(CommonPaymentService) {
  public get delivery(): DeliveryDto {
    return this.$store.state['appMainModule'].delivery;
  }

  public get isPaymentPopupShown(): boolean {
    return this.$store.state['appMainModule'].isPaymentPopupShown;
  }

  public set isPaymentPopupShown(val: boolean) {
    this.$store.commit('appMainModule/setPaymentPopup', val);
  }

  public async downloadQRCode(): Promise<void> {
    if (this.delivery.channelCode == null || this.delivery.channelCode !== 'QRISPAY' || this.delivery.paymentCode == null) {
      return;
    }

    await this.setDownloadQRCode(this.delivery.paymentCode);
  }

  public copyPaymentCode(): void {
    if (this.delivery.channelCode == null || this.delivery.channelCode === 'QRISPAY' && this.delivery.paymentCode == null) {
      return;
    }

    this.setCopyPaymentCode(this.delivery.paymentCode as string);
  }

  public howToPay(): void {
    if (this.delivery.channelCode == null) {
      return;
    }

    this.setHowToPay(this.delivery.channelCode);
  }
}

export default DeliveryService;
