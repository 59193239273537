import HowToPay from '@/app/types/HowToPay';

const HowToPayBriva: HowToPay[] = [
  {
    title: 'ATM BRI',
    list: [
      'Masukkan Kartu Debit BRI dan PIN Anda',
      'Pilih menu Transaksi Lain &gt; Pembayaran &gt; Lainnya &gt; BRIVA',
      'Masukkan angka kode pembayaran',
      'Di halaman konfirmasi, pastikan detil pembayaran sudah sesuai seperti Nomor BRIVA, Nama Pelanggan dan Jumlah Pembayaran',
      'Ikuti instruksi untuk menyelesaikan transaksi',
      'Simpan struk transaksi sebagai bukti pembayaran'
    ],
  },
  {
    title: 'Mobile banking BRI',
    list: [
      'Login aplikasi BRI Mobile',
      'Pilih menu Mobile Banking BRI &gt; Pembayaran &gt; BRIVA',
      'Masukkan angka kode pembayaran',
      'Masukan Jumlah Pembayaran',
      'Masukkan PIN',
      'Simpan notifikasi SMS sebagai bukti pembayaran'
    ],
  },
  {
    title: 'Internet banking BRI',
    list: [
      'Login pada alamat Internet Banking BRI (https://ib.bri.co.id/ib-bri/Login.html)',
      'Pilih menu Pembayaran Tagihan &gt; Pembayaran &gt; BRIVA',
      'Pada kolom kode bayar, Masukkan angka kode pembayaran',
      'Di halaman konfirmasi, pastikan detil pembayaran sudah sesuai seperti Nomor BRIVA, Nama Pelanggan dan Jumlah Pembayaran',
      'Masukkan passworddan mToken',
      'Cetak/simpan struk pembayaran BRIVA sebagai bukti pembayaran'
    ],
  },
  {
    title: 'Mini ATM BRI',
    list: [
      'Pilih menu Mini ATM &gt; Pembayaran &gt; BRIVA',
      'SwipeKartu Debit BRI Anda',
      'Masukkan angka kode pembayaran',
      'Masukkan PIN',
      'Di halaman konfirmasi, pastikan detil pembayaran sudah sesuai seperti Nomor BRIVA, Nama Pelanggan dan Jumlah Pembayaran',
      'Simpan struk transaksi sebagai bukti pembayaran'
    ],
  },
  {
    title: 'Kantor bank BRI',
    list: [
      'Ambil nomor antrian transaksi Teller dan isi slip setoran',
      'Serahkan slip dan jumlah setoran kepada Teller BRI',
      'Teller BRI akan melakukan validasi transaksi',
      'Simpan slip setoran hasil validasi sebagai bukti pembayaran'
    ],
  },
  {
    title: 'ATM bank lain',
    list: [
      'Masukkan Kartu Debit dan PIN Anda',
      'Pilih menu Transaksi Lainnya &gt; Transfer &gt; Ke Rek Bank Lain',
      'Masukkan kode bank BRI (002) kemudian Masukkan angka kode pembayaran',
      'Ikuti instruksi untuk menyelesaikan transaksi',
      'Simpan struk transaksi sebagai bukti pembayaran'
    ],
  },
];

export default HowToPayBriva;
