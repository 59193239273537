
import { Component, Vue } from 'vue-property-decorator';
import PopupContainer from '@/app/components/PopupContainer.vue';
import HowToPay from '@/app/types/HowToPay';
import HowToPayBriva from '@/app/data/HowToPayBriva';

@Component({
  components: {
    PopupContainer,
  }
})
export default class HowToPayBrivaPopup extends Vue {
  private howToData: HowToPay[] = HowToPayBriva;

  private get isHowToPayBrivaPopupShown(): boolean {
    return this.$store.state['appMainModule'].isHowToPayBrivaPopupShown;
  }

  private set isHowToPayBrivaPopupShown(val: boolean) {
    this.$store.commit('appMainModule/setHowToPayBriva', val);
  }
}
