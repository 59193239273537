
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component
export default class PopupContainer extends Vue {
  @Prop({ type: String, default: '' }) readonly wrapperClass!: string;

  @Prop({ type: String, default: '' }) readonly overlayClass!: string;

  @Prop({ type: String, default: '' }) readonly contentClass!: string;

  @Prop({ type: String, default: '' }) readonly headerClass!: string;

  @Prop({ type: Boolean, default: false }) readonly hideHeader!: boolean;

  @Prop({ type: Boolean, default: false }) readonly isLoading!: boolean;

  @Emit()
  onBlur(): void {
    this.$emit('onBlur');
  }

}
