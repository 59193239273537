import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import AppMainModule from '@/app/store/modules/app-main.module';

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';

export default new Store({
  modules: {
    appMainModule: AppMainModule
  },
  strict: debug,
});
