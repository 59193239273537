
import { Component, Mixins } from 'vue-property-decorator';
import AppService from '@/app/services/app.service';
import Loader from '@/app/components/Loader.vue';
import NotFoundMessage from '@/app/components/NotFoundMessage.vue';
import Invoice from '@/app/invoice/Invoice.vue';
import Delivery from '@/app/delivery/Delivery.vue';

@Component({
  components: {
    Loader,
    NotFoundMessage,
    Invoice,
    Delivery
  },
})
export default class App extends Mixins(AppService) {}
