import { Component, Vue } from 'vue-property-decorator';
import PaymentMethod from '@/app/types/PaymentMethod';
import axios from 'axios';
import API_ENDPOINTS from '../endpoints';
import PaymentMethodResponseDto from '@/app/types/PaymentMethodResponseDto';
import InvoiceDto from '@/app/types/InvoiceDto';
import PayInvoicePayloadDto from '../types/PayInvocePayloadDto';
import DeliveryDto from '@/app/types/DeliveryDto';
import PayDeliveryPayloadDto from '@/app/types/PayDeliveryPayloadDto';

@Component
class PaymentPopupService extends Vue {
  public isLoading = false;

  public modernStores: PaymentMethod[] = [];

  public virtualAccounts: PaymentMethod[] = [];

  public qris: PaymentMethod[] = [];

  public selectedPaymentMethod: PaymentMethod | null = null;

  public get availablePaymentMethodsAmount(): number {
    return this.modernStores.length + this.virtualAccounts.length + this.qris.length;
  }

  private get token(): string {
    return this.$store.state['appMainModule'].token;
  }

  private get invoiceId(): string {
    return this.$store.state['appMainModule'].invoiceId;
  }

  public get invoice(): InvoiceDto {
    return this.$store.state['appMainModule'].invoice;
  }

  public get delivery(): DeliveryDto {
    return this.$store.state['appMainModule'].delivery;
  }

  private get deliveryId(): string {
    return this.$store.state['appMainModule'].deliveryId;
  }

  public get transactionFee(): number {
    if (this.selectedPaymentMethod == null) {
      return 0;
    }

    return this.selectedPaymentMethod.nominal_admin;
  }

  public get transactionTotal(): number {
    if (this.invoice != null) {
      return this.invoice.total;
    } else if (this.delivery != null) {
      return this.delivery.totalHarga;
    } else {
      return 0;
    }
  }

  public get transactionTotalWithFee(): number {
    return this.transactionTotal + this.transactionFee;
  }

  public get isPaymentPopupShown(): boolean {
    return this.$store.state['appMainModule'].isPaymentPopupShown;
  }

  public set isPaymentPopupShown(val: boolean) {
    this.$store.commit('appMainModule/setPaymentPopup', val);
  }

  mounted(): void {
    this.fetchPaymentMethods();
  }

  private async fetchPaymentMethods(): Promise<void> {
    this.isLoading = true;
    const headers = { 'X-API-TOKEN': this.token };

    try {
      let data;
      if (this.invoice != null) {
        data = await axios.get(API_ENDPOINTS.FETCH_INVOICE_PAYMENT_METHODS(this.invoiceId), { headers });
      } else if (this.delivery != null) {
        data = await axios.get(API_ENDPOINTS.FETCH_DELIVERY_PAYMENT_METHODS(this.deliveryId), { headers });
      } else {
        throw 'Data not defined';
      }

      const res: PaymentMethodResponseDto = data.data.data.data;

      if (res.modern_store != null && res.modern_store.length > 0) {
        this.modernStores = res.modern_store;
      }

      if (res.virtual_account != null && res.virtual_account.length > 0) {
        this.virtualAccounts = res.virtual_account;
      }

      if (res.qris != null && res.qris.length > 0) {
        this.qris = res.qris;
      }
    } catch (err) {
      console.log('Error fetch payment methods');
    } finally {
      this.isLoading = false;
    }
  }

  public async pay(): Promise<void> {
    if (this.isLoading || this.selectedPaymentMethod == null) {
      return;
    }

    this.isLoading = true;
    const payHeaders = { 'X-API-TOKEN': this.token, 'Content-Type': 'application/json' };
    const fetchHeaders = { 'X-API-TOKEN': this.token };
    
    try {
      if (this.invoice != null) {
        const payload: PayInvoicePayloadDto = {
          invoiceId: this.invoiceId,
          channelCode: this.selectedPaymentMethod.payment_code,
        };
  
        await axios.post(API_ENDPOINTS.PAY_INVOICE, payload, { headers: payHeaders });
        const { data } = await axios.get(API_ENDPOINTS.FETCH_INVOICE(this.invoiceId), { headers: fetchHeaders });
        
        const invoiceRes: InvoiceDto = data.data;
        this.$store.commit('appMainModule/setInvoice', invoiceRes);
      } else if (this.delivery != null) {
        const payload: PayDeliveryPayloadDto = {
          ekspedisiId: this.deliveryId,
          channelCode: this.selectedPaymentMethod.payment_code,
        };
        
        await axios.post(API_ENDPOINTS.PAY_DELIVERY, payload, { headers: payHeaders });
        const { data } = await axios.get(API_ENDPOINTS.FETCH_DELIVERY(this.deliveryId), { headers: fetchHeaders });
        
        const deliveryRes: DeliveryDto = data.data;
        this.$store.commit('appMainModule/setDelivery', deliveryRes);
      } else {
        throw 'Data not defined';
      }
      
      this.isPaymentPopupShown = false;
    } catch (error) {
      console.log('Error when do payment');
    } finally {
      this.isLoading = false;
    }
  }
}

export default PaymentPopupService;
