declare const API_URL: string;

const API_ENDPOINTS = {
  FETCH_INVOICE: (invoiceId: string) => `${API_URL}invoice/${invoiceId}`,
  FETCH_INVOICE_PAYMENT_METHODS: (invoiceId: string) => `${API_URL}invoice-list-payment/${invoiceId}`,
  PAY_INVOICE: `${API_URL}invoice-do-payment`,
  FETCH_DELIVERY: (deliveryId: string) => `${API_URL}ekspedisi/${deliveryId}`,
  FETCH_DELIVERY_PAYMENT_METHODS: (deliveryId: string) => `${API_URL}ekspedisi-list-payment/${deliveryId}`,
  PAY_DELIVERY: `${API_URL}ekspedisi-do-payment`,
};

export default API_ENDPOINTS;
