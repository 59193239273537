import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
import InvoiceDto from '@/app/types/InvoiceDto';
import API_ENDPOINTS from '@/app/endpoints';
import DeliveryDto from '@/app/types/DeliveryDto';

@Component
class AppService extends Vue {
  public isLoading = true;

  public get invoice(): InvoiceDto | null {
    return this.$store.state['appMainModule'].invoice;
  }

  private get token(): string {
    return this.$store.state['appMainModule'].token;
  }

  private get invoiceId(): string {
    return this.$store.state['appMainModule'].invoiceId;
  }

  public get delivery(): DeliveryDto | null {
    return this.$store.state['appMainModule'].delivery;
  }

  private get deliveryId(): string {
    return this.$store.state['appMainModule'].deliveryId;
  }

  public get isDataEmpty(): boolean {
    return this.invoice == null && this.delivery == null;
  }

  mounted(): void {
    this.initData();
  }

  private async initData(): Promise<void> {
    const usp = new URLSearchParams(window.location.search);
    const token = usp.get('t');
    const invoiceId = usp.get('i');
    const deliveryId = usp.get('d');

    if (token != null) {
      this.$store.commit('appMainModule/setToken', token);
    }

    if (invoiceId != null) {
      this.$store.commit('appMainModule/setInvoiceId', invoiceId);
    }

    if (deliveryId != null) {
      this.$store.commit('appMainModule/setDeliveryId', deliveryId);
    }

    try {
      if (invoiceId != null) {
        await this.fetchInvoice();
      } else if (deliveryId != null) {
        await this.fetchDelivery();
      }

      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    } catch (err) {
      this.isLoading = false;
    }
  }

  private async fetchInvoice(): Promise<void> {
    if (this.token.length === 0 || this.invoiceId.length === 0) {
      return;
    }

    try {
      const { data } = await axios.get(API_ENDPOINTS.FETCH_INVOICE(this.invoiceId), { headers: { 'X-API-TOKEN': this.token } });

      if (data != null) {
        const invoiceRes: InvoiceDto = data.data;
        this.$store.commit('appMainModule/setInvoice', invoiceRes);
      }
    } catch (err) {
      console.log('Error fetch invoice');
    }
  }

  private async fetchDelivery(): Promise<void> {
    if (this.token.length === 0 || this.deliveryId.length === 0) {
      return;
    }

    try {
      const { data } = await axios.get(API_ENDPOINTS.FETCH_DELIVERY(this.deliveryId), { headers: { 'X-API-TOKEN': this.token } });

      if (data != null) {
        const deliveryRes: DeliveryDto = data.data;
        this.$store.commit('appMainModule/setDelivery', deliveryRes);
      }
    } catch (err) {
      console.log('Error fetch delivery');
    }
  }
}

export default AppService;
