
import { Component, Vue } from 'vue-property-decorator';
import PopupContainer from '@/app/components/PopupContainer.vue';
import HowToPay from '@/app/types/HowToPay';
import HowToPayMandiriva from '@/app/data/HowToPayMandiriva';

@Component({
  components: {
    PopupContainer,
  }
})
export default class HowToPayMandirivaPopup extends Vue {
  private howToData: HowToPay[] = HowToPayMandiriva;

  private get isHowToPayMandirivaPopupShown(): boolean {
    return this.$store.state['appMainModule'].isHowToPayMandirivaPopupShown;
  }

  private set isHowToPayMandirivaPopupShown(val: boolean) {
    this.$store.commit('appMainModule/setHowToPayMandiriva', val);
  }
}
