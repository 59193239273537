import Vue from 'vue';
import App from '@/app/App.vue';
import store from '@/app/store/index';
import '../css/main.scss';
import filters from './plugins/filters';

Vue.use(filters);

Vue.config.productionTip = false;

new Vue({
  store,
  render: (h) => h(App),
}).$mount('#app');
