
import { Component, Vue } from 'vue-property-decorator';
import PopupContainer from '@/app/components/PopupContainer.vue';
import InvoiceDto from '@/app/types/InvoiceDto';
import DeliveryDto from '@/app/types/DeliveryDto';

@Component({
  components: {
    PopupContainer,
  }
})
export default class HowToPayIndomaretPopup extends Vue {
  private get isHowToPayIndomaretPopupShown(): boolean {
    return this.$store.state['appMainModule'].isHowToPayIndomaretPopupShown;
  }

  private set isHowToPayIndomaretPopupShown(val: boolean) {
    this.$store.commit('appMainModule/setHowToPayIndomaret', val);
  }

  private get invoice(): InvoiceDto {
    return this.$store.state['appMainModule'].invoice;
  }

  private get delivery(): DeliveryDto {
    return this.$store.state['appMainModule'].delivery;
  }

  private get paymentCode(): string {
    if (this.invoice != null) {
      return this.invoice.paymentCode || '';
    } else if (this.delivery != null) {
      return this.delivery.paymentCode || '';
    } else {
      return '';
    }
  }
}
