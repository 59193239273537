import { Component, Mixins } from 'vue-property-decorator';
import moment from 'moment';
import InvoiceDto from '@/app/types/InvoiceDto';
import CommonPaymentService from './common-payment.service';

@Component
class InvoiceService extends Mixins(CommonPaymentService) {
  public moment: typeof moment = moment;

  public get invoice(): InvoiceDto {
    return this.$store.state['appMainModule'].invoice;
  }

  public get isPaymentPopupShown(): boolean {
    return this.$store.state['appMainModule'].isPaymentPopupShown;
  }

  public set isPaymentPopupShown(val: boolean) {
    this.$store.commit('appMainModule/setPaymentPopup', val);
  }

  public async downloadQRCode(): Promise<void> {
    if (this.invoice.channelCode == null || this.invoice.channelCode !== 'QRISPAY' || this.invoice.paymentCode == null) {
      return;
    }

    await this.setDownloadQRCode(this.invoice.paymentCode);
  }

  public copyPaymentCode(): void {
    if (this.invoice.channelCode == null || this.invoice.channelCode === 'QRISPAY' && this.invoice.paymentCode == null) {
      return;
    }

    this.setCopyPaymentCode(this.invoice.paymentCode as string);
  }

  public howToPay(): void {
    if (this.invoice.channelCode == null) {
      return;
    }

    this.setHowToPay(this.invoice.channelCode);
  }
}

export default InvoiceService;
